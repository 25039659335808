import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Row, Col, Card, Form, Input, Select, Collapse, Switch, Button, DatePicker, Avatar, Upload, Modal, Table } from 'antd';
import config from '../../config';
import api from '../../helpers/api';
import moment from 'moment';
import md5 from 'md5';
import Spinner from '../elements/Spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const { Panel } = Collapse;
const { Option } = Select;
const { Meta } = Card;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

let suburbLookupTimeout;
let currentSuburb;

class DTProfile extends React.Component {
    state = {
        driverData: [],
        loading: true,
        suburbs: [],
        otherGenderVisible: false,
        downloading: false,
        uploading: false,
        dtComplianceModalVisible: false,
        dtComplianceModalHeader: null,
        dtComplianceModalField: null
    };

    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate() {
        if(typeof(this.state.driverData.id) !== 'undefined' && parseInt(this.props.chosen_driver) !== parseInt(this.state.driverData.id)){
            this.fetch();
        }
    }

    updateDateValue = (fieldName, e) => {
        //Convert moment to a MySQL friendly date and call the updateValue function (note reversed order of variables)
        this.updateValue(e.format('YYYY-MM-DD HH:mm:ss'), fieldName);
    };

    updateAddressValue = (value, field) => {
        //Only update if the new Value is different from the existing.
        if(value !== this.state.driverData.user.address[field]){
            api.post("drivers/update/address", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    field: field,
                    value: value
                })
            })
            .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    console.log(res);
                } else {
                    //Update the state driverData to reflect the change
                    let driverObject = Object.assign({}, this.state.driverData);
                    driverObject.user.address[field] = value;
                    this.setState({driverData: driverObject});
                }
            })
            .catch(error => {
                console.log(error);
                //@todo: We should show an error/alert to the user.
            });
        }
    }

    updateValue = (e, fieldName) => {
        let field = (typeof fieldName !== 'undefined' ? fieldName : e.id.substring(16));
        let value = (typeof fieldName !== 'undefined' ? e : e.value);

        //Only update if the new Value is different from the existing.
        let doUpdate = true;

        // If we have a single . then we are updating one level deep.
        // Note that at this point, we do not have any references in the form below to further nested objects.
        // If/when that occurs, this will need to be replaced with a recursive function (using `if((field.match(/\./g)||[]).length > 0){` as a base
        if(field.includes('.')){
            let before = field.substr(0, field.indexOf('.'));
            let after = field.substr(field.indexOf('.')+1);
            if(value === this.state.driverData[before][after]){
                doUpdate = false;
            }
        }else{
            //Otherwise, we simply compare the current state to the submitted value.
            if(value === this.state.driverData[field]){
                doUpdate = false;
            }
        }
        
        if(doUpdate){
             api.post("drivers/update", {
                 body: JSON.stringify({
                     chosen_driver: this.props.chosen_driver,
                     //Strip the 'driver_profile_form_' from the field name if required
                     field: field,
                     value: value
                 })
             })
             .then(res => {
                //Convert to JSON in case we've received a string response
                if(typeof res === 'string'){
                    res = JSON.parse(res);
                }
                
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK') {
                    console.log(res);
                } else {
                    //Update the state driverData to reflect the change
                    let driverObject = Object.assign({}, this.state.driverData);
                    //As above, this currently relies on an object depth of one
                    if(field.includes('.')){
                        let before = field.substr(0, field.indexOf('.'));
                        let after = field.substr(field.indexOf('.')+1);
                        driverObject[before][after] = value;
                    }else{
                        driverObject[field] = value;
                    }
                    this.setState({driverData: driverObject});
                }
             })
             .catch(error => {
                 console.log(error);
                 //@todo: We should show an error/alert to the user.
             });
        }
    };

    toggleTransmission = (e) => {
        this.updateValue((e ? "A" : "M"), 'vehicle_transmission');
    };

    toggleIndicator = (e) => {
        this.updateValue((e ? "Right" : "Left"), 'vehicle_indicator_side');
    };

    handleSearch = value => {
        this.suburbLookup(value, suburbs => this.setState({ suburbs }));
    };

    suburbLookup = (value, callback) => {
        if (suburbLookupTimeout) {
            clearTimeout(suburbLookupTimeout);
            suburbLookupTimeout = null;
        }
        currentSuburb = value;

        function debouncedLookup() {
            api.post("suburbs/search", {
                body: JSON.stringify({
                    search: value
                })
            })
            .then(d => {
                if (currentSuburb === value) {
                    const data = [];
                    d.result.suburbs.forEach(r => {
                        data.push({
                            value: r.id,
                            text: r.suburb_name
                        });
                    });
                    callback(data);
                }
            });
        }
        suburbLookupTimeout = setTimeout(debouncedLookup, 300);
    }

    fetch = async(params = {}) => {
        await this.setState({
            loading: true
        }, async () => {
            await api.post("drivers/profile", {
                body: JSON.stringify({
                    chosen_driver: this.props.chosen_driver,
                    ...params
                })
            })
            .then(res => {
                //Check for an error response (status of "NOK")
                if(res.status === 'NOK' || typeof res.result.driver === 'undefined') {
                    this.props.history.push('/');
                } else {
                    this.setState({
                        loading: false,
                        driverData: res.result.driver,
                        otherGenderVisible: (res.result.driver.user.gender === 'Other')
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.props.history.push('/');
            });
        });
    };

    download_paperwork = async (id) => {
        this.setState({downloading: true});
        await api.post("drivers/download/" + id, {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver
            })
        })
        .then(res => {
            const a = document.createElement('a');
            a.download = res.result.filename;
            a.href = config.apiRoot + '/' + res.result.path;
            a.click();
            this.setState({downloading: false});
        })
        .catch(error => {
            console.log(error);
            this.setState({downloading: false});
        });
    };

    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 7}
            },
            wrapperCol: {
                xs: {span: 17}
            }
        };
        const suburbs = this.state.suburbs.map(d => <Option key={d.value}>{d.text}</Option>);

        let infobox = (
            <div style={{textAlign: 'left'}}>
                <b>Current ALPIS:</b> ???<br />
                <b>@todo:</b> What stats would we like to show here??
            </div>
        );

        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    {
                    this.state.loading ? 
                        (
                            <Spinner type="mega" />
                        ) : (
                            <>
                                <h2>{this.state.driverData.user.first_name + ' ' + this.state.driverData.user.last_name}</h2>
                                <Card style={{ display: 'none', width: 500, margin: '5px auto 20px', height: 'unset' }} >
                                    <Meta
                                        avatar={
                                            <Avatar src={"https://www.gravatar.com/avatar/" + md5(this.state.driverData.user.email) + "?s=200&d=mp"} />
                                        }
                                        title={this.state.driverData.user.first_name + ' ' + this.state.driverData.user.last_name}
                                        description={infobox}
                                    />
                                </Card>

                                <Row type="flex" style={{justifyContent: 'space-evenly'}} >
                                    <Link to={"/availability"}>
                                        <Button type="primary" htmlType="button">
                                            <FontAwesomeIcon icon={faClock} />View Availability
                                        </Button>
                                    </Link>
                                </Row>

                                <Form {...formItemLayout} id="dtProfileForm">
                                    <Collapse style={{textAlign: 'left'}}>
                                        <Panel header="Personal / Contact Details" key="1">
                                            <fieldset>
                                                <legend>Personal Information</legend>
                                                <Form.Item label="First Name" >
                                                    {getFieldDecorator('user.first_name', {
                                                        rules: [{ required: true, message: 'Please enter a First Name.' }],
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' ? this.state.driverData.user.first_name : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Middle Name" >
                                                    {getFieldDecorator('user.middle_name', {
                                                        rules: [{ required: false, message: 'Please enter a Middle Name.' }],
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' ? this.state.driverData.user.middle_name : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Last Name" >
                                                    {getFieldDecorator('user.last_name', {
                                                        rules: [{ required: true, message: 'Please enter a Last Name.' }],
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' ? this.state.driverData.user.last_name : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>

                                                <Form.Item label="Date of Birth" >
                                                    {getFieldDecorator('user.dob', {
                                                        rules: [{ required: true, message: 'Please enter a Date of Birth.' }],
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' && this.state.driverData.user.dob !== null ? moment(this.state.driverData.user.dob) : null)
                                                    })(
                                                        <DatePicker
                                                            disabled={!this.props.office_user ? true : false} 
                                                            required="required"
                                                            allowClear={false}
                                                            placeholder='Select Due Date'
                                                            format={dateFormatList}
                                                            onChange={(e) => this.updateDateValue('user.dob', e)}
                                                        />
                                                    )}
                                                </Form.Item>

                                                <Form.Item label="Gender">
                                                    {getFieldDecorator('gender', {
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' && this.state.driverData.user.gender === 'Unknown' ? null : this.state.driverData.user.gender),
                                                        rules: [{ required: true, message: 'Please enter a Gender.' }]
                                                    })(
                                                        <Select
                                                            placeholder="Select Gender"
                                                            defaultActiveFirstOption={false}
                                                            filterOption={false}
                                                            notFoundContent={null}
                                                            disabled={!this.props.office_user ? true : false}
                                                            onChange={(value) => {
                                                                this.updateValue(value, 'user.gender');
                                                                if (value !== 'Other') {
                                                                    this.props.form.setFieldsValue({
                                                                        gender_other: null
                                                                    });
                                                                }
                                                                this.setState({
                                                                    otherGenderVisible: (value === 'Other')
                                                                }, () => {
                                                                    if (this.state.otherGenderVisible) {
                                                                        this.genderOther.focus();
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            <Option value="F">Female</Option>
                                                            <Option value="M">Male</Option>
                                                            <Option value="Other">Other</Option>
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                                {this.state.otherGenderVisible && 
                                                    <Form.Item label="Gender (Please Enter)">
                                                        {getFieldDecorator('gender_other', {
                                                            initialValue: (typeof(this.state.driverData.user.user_gender) !== 'undefined' && this.state.driverData.user.user_gender !== null ? this.state.driverData.user.user_gender.gender : null),
                                                            rules: [
                                                                { required: true, message: 'Please enter preferred Gender term.' },
                                                                { max: 255, message: 'Term cannot be more than 255 characters.' }
                                                            ]
                                                        })(
                                                            <Input
                                                                ref={(input) => { this.genderOther = input; }}
                                                                maxLength={255}
                                                                onBlur={(e) => this.updateValue(e.target.value, 'user.user_gender.gender')}
                                                                readOnly={!this.props.office_user ? true : false}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                }

                                                <Form.Item label="Display Name" >
                                                    {getFieldDecorator('display_name', {
                                                        rules: [{ required: true, message: 'Please enter a Display Name.' }],
                                                        initialValue: (typeof this.state.driverData.display_name !== 'undefined' ? this.state.driverData.display_name : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>

                                                <Form.Item label="Terminated" style={{display: (typeof this.state.driverData !== 'undefined' && this.state.driverData.terminated !== null ? 'block' : 'none')}}>
                                                    {getFieldDecorator('terminated', {
                                                        rules: [],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' && this.state.driverData.terminated !== null ? moment(this.state.driverData.terminated) : null)
                                                    })(
                                                        <DatePicker
                                                            disabled={!this.props.office_user ? true : false} 
                                                            allowClear={false}
                                                            placeholder='Select Termination Date'
                                                            format={dateFormatList}
                                                            onChange={(e) => this.updateDateValue('terminated', e)}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </fieldset>

                                            <fieldset>
                                                <legend>Contact Information</legend>
                                                <Form.Item label="Home Phone" >
                                                    {getFieldDecorator('user.home_phone', {
                                                        rules: [{ required: true, message: 'Please enter a Home Phone.' }],
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' ? this.state.driverData.user.home_phone : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Mobile Phone" >
                                                    {getFieldDecorator('user.mobile_phone', {
                                                        rules: [{ required: true, message: 'Please enter a Mobile Phone.' }],
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' ? this.state.driverData.user.mobile_phone : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Email" >
                                                    {getFieldDecorator('user.email', {
                                                        rules: [{ required: true, message: 'Please enter an Email Address.' }],
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' ? this.state.driverData.user.email : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </fieldset>

                                            <fieldset>
                                                <legend>Home Address</legend>
                                                <Form.Item label="Street Number" >
                                                    {getFieldDecorator('user.address.street_number', {
                                                        rules: [
                                                            { required: true, message: 'Please enter a Street Number.' },
                                                            { max: 10, message: 'Street Number cannot be more than 10 characters.' },
                                                        ],
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' ? this.state.driverData.user.address.street_number : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateAddressValue(e.target.value, 'street_number')}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Street Name" >
                                                    {getFieldDecorator('user.address.street_name', {
                                                        rules: [{ required: true, message: 'Please enter a Street Name.' }],
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' ? this.state.driverData.user.address.street_name : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateAddressValue(e.target.value, 'street_name')}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Suburb" >
                                                    {getFieldDecorator('user.address.suburb.suburb_name', {
                                                        rules: [{ required: true, message: 'Please enter a Suburb.' }],
                                                        initialValue: (typeof this.state.driverData.user !== 'undefined' ? this.state.driverData.user.address.suburb.suburb_name + ", " + this.state.driverData.user.address.suburb.post_code : null)
                                                    })(
                                                        <Select
                                                            disabled={!this.props.office_user ? true : false}
                                                            showSearch
                                                            placeholder="Select Suburb"
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={false}
                                                            onSearch={this.handleSearch}
                                                            onSelect={(e) => this.updateAddressValue(e, 'suburb_id')}
                                                            onFocus={this.disableAutoComplete}
                                                            notFoundContent={null}
                                                        >
                                                            {suburbs}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </fieldset>

                                            <fieldset>
                                                <legend>ABN Details</legend>
                                                <Form.Item label="ABN" >
                                                    {getFieldDecorator('abn', {
                                                        rules: [{ required: true, message: 'Please enter an ABN.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.abn : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="ABN Name" >
                                                    {getFieldDecorator('abn_name', {
                                                        rules: [{ required: false, message: 'Please enter an ABN Name.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.abn_name : null)
                                                    })(
                                                        <Input
                                                            required={false}
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </fieldset>

                                            <fieldset>
                                                <legend>Emergency Contact</legend>
                                                <Form.Item label="Name" >
                                                    {getFieldDecorator('emergency_contact_name', {
                                                        rules: [{ required: true, message: 'Please enter a Name.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.emergency_contact_name : null)
                                                    })(
                                                        <Input
                                                            required={false}
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Relationship" >
                                                    {getFieldDecorator('emergency_contact_relationship', {
                                                        rules: [{ required: true, message: 'Please enter a Relationship.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.emergency_contact_relationship : null)
                                                    })(
                                                        <Select
                                                            required={false}
                                                            disabled={!this.props.office_user ? true : false}
                                                            onSelect={(e) => this.updateValue(e, 'emergency_contact_relationship')}
                                                        >
                                                            <Option value="Husband">Husband</Option>
                                                            <Option value="Wife">Wife</Option>
                                                            <Option value="Father">Father</Option>
                                                            <Option value="Mother">Mother</Option>
                                                            <Option value="Sister">Sister</Option>
                                                            <Option value="Brother">Brother</Option>
                                                            <Option value="Aunt">Aunt</Option>
                                                            <Option value="Uncle">Uncle</Option>
                                                            <Option value="Friend">Friend</Option>
                                                            <Option value="Other">Other</Option>
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Phone" >
                                                    {getFieldDecorator('emergency_contact_phone', {
                                                        rules: [{ required: true, message: 'Please enter a Phone Number.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.emergency_contact_phone : null)
                                                    })(
                                                        <Input
                                                            required={false}
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </fieldset>
                                        </Panel>

                                        <Panel header="Vehicle Details" key="2">
                                            <Form.Item label="Make" >
                                                {getFieldDecorator('vehicle_make', {
                                                    rules: [{ required: true, message: 'Please enter a Vehicle Make.' }],
                                                    initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_make : null)
                                                })(
                                                    <Input
                                                        required="required"
                                                        readOnly={!this.props.office_user ? true : false}
                                                        onBlur={(e) => this.updateValue(e.target)}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Model" >
                                                {getFieldDecorator('vehicle_model', {
                                                    rules: [{ required: true, message: 'Please enter a Vehicle Model.' }],
                                                    initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_model : null)
                                                })(
                                                    <Input
                                                        required="required"
                                                        readOnly={!this.props.office_user ? true : false}
                                                        onBlur={(e) => this.updateValue(e.target)}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Year" >
                                                {getFieldDecorator('vehicle_year', {
                                                    rules: [{ required: true, message: 'Please enter a Vehicle Year.' }],
                                                    initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_year : null)
                                                })(
                                                    <Input
                                                        required="required"
                                                        readOnly={!this.props.office_user ? true : false}
                                                        onBlur={(e) => this.updateValue(e.target)}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Month" >
                                                {getFieldDecorator('vehicle_month', {
                                                    rules: [{ required: true, message: 'Please enter a Vehicle Month.' }],
                                                    initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_month : null)
                                                })(
                                                    <Select
                                                        required="required"
                                                        disabled={!this.props.office_user ? true : false}
                                                        onSelect={(e) => this.updateValue(e, 'vehicle_month')}
                                                    >
                                                        <Option value="01">January</Option>
                                                        <Option value="02">February</Option>
                                                        <Option value="03">March</Option>
                                                        <Option value="04">April</Option>
                                                        <Option value="05">May</Option>
                                                        <Option value="06">June</Option>
                                                        <Option value="07">July</Option>
                                                        <Option value="08">August</Option>
                                                        <Option value="09">September</Option>
                                                        <Option value="10">October</Option>
                                                        <Option value="11">November</Option>
                                                        <Option value="12">December</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            {typeof this.state.driverData !== 'undefined' && this.state.driverData.vehicle_warning && (
                                                <Row>
                                                    <Col
                                                        className="compliance-warning"
                                                        xs={{ span: 24 }}
                                                        sm={{ span: 17, offset: 7 }}
                                                    >
                                                        <Alert
                                                            message="Vehicle is older than 3.5 years."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                            <Form.Item label="Body" >
                                                {getFieldDecorator('vehicle_body', {
                                                    rules: [{ required: true, message: 'Please enter a Vehicle Body Shape.' }],
                                                    initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_body : null)
                                                })(
                                                    <Input
                                                        required="required"
                                                        readOnly={!this.props.office_user ? true : false}
                                                        onBlur={(e) => this.updateValue(e.target)}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Colour" >
                                                {getFieldDecorator('vehicle_colour', {
                                                    rules: [{ required: true, message: 'Please enter a Vehicle Colour.' }],
                                                    initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_colour : null)
                                                })(
                                                    <Input
                                                        required="required"
                                                        readOnly={!this.props.office_user ? true : false}
                                                        onBlur={(e) => this.updateValue(e.target)}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Transmission" >
                                                <Switch
                                                    disabled={!this.props.office_user ? true : false}
                                                    checkedChildren="Automatic" 
                                                    unCheckedChildren="Manual" 
                                                    defaultChecked={this.state.driverData.vehicle_transmission === "A" ? true : false}
                                                    onChange={this.toggleTransmission}
                                                />
                                            </Form.Item>
                                            <Form.Item label="Indicator Side" >
                                                <Switch
                                                    disabled={!this.props.office_user ? true : false}
                                                    checkedChildren="Right" 
                                                    unCheckedChildren="Left" 
                                                    defaultChecked={this.state.driverData.vehicle_indicator_side === "Right" ? true : false}
                                                    onChange={this.toggleIndicator}
                                                />
                                            </Form.Item>
                                            <Form.Item label="Fuel Type" >
                                                {getFieldDecorator('vehicle_fuel_type', {
                                                    rules: [{ required: false }],
                                                    initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_fuel_type : null)
                                                })(
                                                    <Select
                                                        required="required"
                                                        disabled={!this.props.office_user ? true : false}
                                                        onSelect={(e) => this.updateValue(e, 'vehicle_fuel_type')}
                                                    >
                                                        <Option value="">Please Select</Option>
                                                        <Option value="Petrol">Petrol</Option>
                                                        <Option value="Hybrid">Hybrid</Option>
                                                        <Option value="Electric">Electric</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Rego" >
                                                {getFieldDecorator('vehicle_rego', {
                                                    rules: [{ required: true, message: 'Please enter a Vehicle Rego.' }],
                                                    initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_rego : null)
                                                })(
                                                    <Input
                                                        required="required"
                                                        readOnly={!this.props.office_user ? true : false}
                                                        onBlur={(e) => this.updateValue(e.target)}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Expiry" >
                                                {getFieldDecorator('vehicle_rego_expiry', {
                                                    rules: [{ required: true, message: 'Please enter an Expiry Date.' }],
                                                    initialValue: (typeof this.state.driverData !== 'undefined' && this.state.driverData.vehicle_rego_expiry !== null ? moment(this.state.driverData.vehicle_rego_expiry) : null)
                                                })(
                                                    <DatePicker
                                                        disabled={!this.props.office_user ? true : false}
                                                        required="required"
                                                        allowClear={false}
                                                        placeholder='Select Expiry Date'
                                                        format={dateFormatList}
                                                        onChange={(e) => this.updateDateValue('vehicle_rego_expiry', e)}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Registration Paperwork" >
                                                <Upload
                                                    name= 'file'
                                                    showUploadList={false}
                                                    action= {config.apiRoot + '/drivers/upload'}
                                                    headers={{
                                                        Authorization: 'Bearer ' + api.getAuthToken()
                                                    }}
                                                    data={{
                                                        chosen_driver: this.props.chosen_driver,
                                                        field: 'vehicle_rego'
                                                    }}
                                                    onChange={(info) => {
                                                        this.setState({uploading: true});
                                                        if (info.file.status === 'done') {
                                                            Modal.success({
                                                                title: "Upload Successful!",
                                                                content: (
                                                                    <div>
                                                                        <p>The franchise team has been notified - they will validate the document and make any necessary changes.</p>
                                                                    </div>
                                                                ),
                                                                onOk: () => {
                                                                    this.fetch();
                                                                    this.setState({uploading: false});
                                                                }
                                                            });
                                                        } else if (info.file.status === 'error') {
                                                            this.setState({uploading: false});
                                                        }
                                                    }}
                                                >
                                                    <Button
                                                        disabled={this.state.uploading}
                                                        loading={this.state.uploading}
                                                    >
                                                        Upload
                                                    </Button>
                                                </Upload>
                                                {typeof(this.state.driverData.driver_trainer_compliance) !== 'undefined' && typeof(this.state.driverData.driver_trainer_compliance.vehicle_rego) !== 'undefined' && this.state.driverData.driver_trainer_compliance.vehicle_rego.length > 0 && (
                                                    <p className="dt-compliance-history">
                                                        <Button
                                                            type="link"
                                                            onClick={() => {this.download_paperwork(this.state.driverData.driver_trainer_compliance.vehicle_rego[0].id);}}
                                                            disabled={this.state.downloading}
                                                            loading={this.state.downloading}
                                                        >
                                                            Current
                                                        </Button>
                                                        {this.state.driverData.driver_trainer_compliance.vehicle_rego.length > 1 && (
                                                            <Button
                                                                type="link"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        dtComplianceModalVisible: true,
                                                                        dtComplianceModalHeader: 'Vehicle Rego',
                                                                        dtComplianceModalField: 'vehicle_rego'
                                                                    });
                                                                }}
                                                            >
                                                                History
                                                            </Button>
                                                        )}
                                                    </p>
                                                )}
                                            </Form.Item>
                                        </Panel>
                                        <Panel header="Licence Details" key="3">
                                            <fieldset>
                                                <legend>Drivers Licence</legend>
                                                <Form.Item label="Number" >
                                                    {getFieldDecorator('driver_licence_number', {
                                                        rules: [{ required: true, message: 'Please enter a Licence Number.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.driver_licence_number : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Type" >
                                                    {getFieldDecorator('driver_licence_type', {
                                                        rules: [{ required: true, message: 'Please enter a Licence Type.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.driver_licence_type : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Expiry" >
                                                    {getFieldDecorator('driver_licence_expiry', {
                                                        rules: [{ required: true, message: 'Please enter a Licence Expiry Date.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' && this.state.driverData.driver_licence_expiry !== null ? moment(this.state.driverData.driver_licence_expiry) : null)
                                                    })(
                                                        <DatePicker
                                                            disabled={!this.props.office_user ? true : false}
                                                            required="required"
                                                            allowClear={false}
                                                            placeholder='Select Expiry Date'
                                                            format={dateFormatList}
                                                            onChange={(e) => this.updateDateValue('driver_licence_expiry', e)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Conditions" >
                                                    {getFieldDecorator('driver_licence_conditions', {
                                                        rules: [{ required: true, message: 'Please enter any Licence Conditions.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.driver_licence_conditions : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Driver Licence Paperwork" >
                                                    <Upload
                                                        name= 'file'
                                                        showUploadList={false}
                                                        action= {config.apiRoot + '/drivers/upload'}
                                                        headers={{
                                                            Authorization: 'Bearer ' + api.getAuthToken()
                                                        }}
                                                        data={{
                                                            chosen_driver: this.props.chosen_driver,
                                                            field: 'driver_licence'
                                                        }}
                                                        onChange={(info) => {
                                                            this.setState({uploading: true});
                                                            if (info.file.status === 'done') {
                                                                Modal.success({
                                                                    title: "Upload Successful!",
                                                                    content: (
                                                                        <div>
                                                                            <p>The franchise team has been notified - they will validate the document and make any necessary changes.</p>
                                                                        </div>
                                                                    ),
                                                                    onOk: () => {
                                                                        this.fetch();
                                                                        this.setState({uploading: false});
                                                                    }
                                                                });
                                                            } else if (info.file.status === 'error') {
                                                                this.setState({uploading: false});
                                                            }
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={this.state.uploading}
                                                            loading={this.state.uploading}
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Upload>
                                                    {typeof(this.state.driverData.driver_trainer_compliance) !== 'undefined' && typeof(this.state.driverData.driver_trainer_compliance.driver_licence) !== 'undefined' && this.state.driverData.driver_trainer_compliance.driver_licence.length > 0 && (
                                                        <p className="dt-compliance-history">
                                                            <Button
                                                                type="link"
                                                                onClick={() => {this.download_paperwork(this.state.driverData.driver_trainer_compliance.driver_licence[0].id);}}
                                                                disabled={this.state.downloading}
                                                                loading={this.state.downloading}
                                                            >
                                                                Current
                                                            </Button>
                                                            {this.state.driverData.driver_trainer_compliance.driver_licence.length > 1 && (
                                                                <Button
                                                                    type="link"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            dtComplianceModalVisible: true,
                                                                            dtComplianceModalHeader: 'Driver Licence',
                                                                            dtComplianceModalField: 'driver_licence'
                                                                        });
                                                                    }}
                                                                >
                                                                    History
                                                                </Button>
                                                            )}
                                                        </p>
                                                    )}
                                                </Form.Item>
                                            </fieldset>
                                            <fieldset>
                                                <legend>Instructors Licence</legend>
                                                <Form.Item label="Number" >
                                                    {getFieldDecorator('instructors_licence_number', {
                                                        rules: [{ required: true, message: 'Please enter a Licence Number.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.instructors_licence_number : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Type" >
                                                    {getFieldDecorator('instructors_licence_type', {
                                                        rules: [{ required: true, message: 'Please enter a Licence Type.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.instructors_licence_type : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Expiry" >
                                                    {getFieldDecorator('instructors_licence_expiry', {
                                                        rules: [{ required: true, message: 'Please enter a Licence Expiry Date.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' && this.state.driverData.instructors_licence_expiry !== null ? moment(this.state.driverData.instructors_licence_expiry) : null)
                                                    })(
                                                        <DatePicker
                                                            disabled={!this.props.office_user ? true : false}
                                                            required="required"
                                                            allowClear={false}
                                                            placeholder='Select Expiry Date'
                                                            format={dateFormatList}
                                                            onChange={(e) => this.updateDateValue('instructors_licence_expiry', e)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Conditions" >
                                                    {getFieldDecorator('instructors_licence_conditions', {
                                                        rules: [{ required: true, message: 'Please enter any Licence Conditions.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.instructors_licence_conditions : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Instructors Licence Paperwork" >
                                                    <Upload
                                                        name= 'file'
                                                        showUploadList={false}
                                                        action= {config.apiRoot + '/drivers/upload'}
                                                        headers={{
                                                            Authorization: 'Bearer ' + api.getAuthToken()
                                                        }}
                                                        data={{
                                                            chosen_driver: this.props.chosen_driver,
                                                            field: 'instructors_licence'
                                                        }}
                                                        onChange={(info) => {
                                                            this.setState({uploading: true});
                                                            if (info.file.status === 'done') {
                                                                Modal.success({
                                                                    title: "Upload Successful!",
                                                                    content: (
                                                                        <div>
                                                                            <p>The franchise team has been notified - they will validate the document and make any necessary changes.</p>
                                                                        </div>
                                                                    ),
                                                                    onOk: () => {
                                                                        this.fetch();
                                                                        this.setState({uploading: false});
                                                                    }
                                                                });
                                                            } else if (info.file.status === 'error') {
                                                                this.setState({uploading: false});
                                                            }
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={this.state.uploading}
                                                            loading={this.state.uploading}
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Upload>
                                                    {typeof(this.state.driverData.driver_trainer_compliance) !== 'undefined' && typeof(this.state.driverData.driver_trainer_compliance.instructors_licence) !== 'undefined' && this.state.driverData.driver_trainer_compliance.instructors_licence.length > 0 && (
                                                        <p className="dt-compliance-history">
                                                            <Button
                                                                type="link"
                                                                onClick={() => {this.download_paperwork(this.state.driverData.driver_trainer_compliance.instructors_licence[0].id);}}
                                                                disabled={this.state.downloading}
                                                                loading={this.state.downloading}
                                                            >
                                                                Current
                                                            </Button>
                                                            {this.state.driverData.driver_trainer_compliance.instructors_licence.length > 1 && (
                                                                <Button
                                                                    type="link"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            dtComplianceModalVisible: true,
                                                                            dtComplianceModalHeader: 'Instructors Licence',
                                                                            dtComplianceModalField: 'instructors_licence'
                                                                        });
                                                                    }}
                                                                >
                                                                    History
                                                                </Button>
                                                            )}
                                                        </p>
                                                    )}
                                                </Form.Item>
                                            </fieldset>
                                            <fieldset>
                                                <legend>Working With Children Check</legend>
                                                <Form.Item label="WWC Number" >
                                                    {getFieldDecorator('working_children_number', {
                                                        rules: [{ required: true, message: 'Please enter a WWC Number.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.working_children_number : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Expiry" >
                                                    {getFieldDecorator('working_children_expiry', {
                                                        rules: [{ required: true, message: 'Please enter an Expiry Date.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' && this.state.driverData.working_children_expiry !== null ? moment(this.state.driverData.working_children_expiry) : null)
                                                    })(
                                                        <DatePicker
                                                            disabled={!this.props.office_user ? true : false}
                                                            required="required"
                                                            allowClear={false}
                                                            placeholder='Select Expiry Date'
                                                            format={dateFormatList}
                                                            onChange={(e) => this.updateDateValue('working_children_expiry', e)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="WWC Paperwork" >
                                                    <Upload
                                                        name= 'file'
                                                        showUploadList={false}
                                                        action= {config.apiRoot + '/drivers/upload'}
                                                        headers={{
                                                            Authorization: 'Bearer ' + api.getAuthToken()
                                                        }}
                                                        data={{
                                                            chosen_driver: this.props.chosen_driver,
                                                            field: 'working_with_children'
                                                        }}
                                                        onChange={(info) => {
                                                            this.setState({uploading: true});
                                                            if (info.file.status === 'done') {
                                                                Modal.success({
                                                                    title: "Upload Successful!",
                                                                    content: (
                                                                        <div>
                                                                            <p>The franchise team has been notified - they will validate the document and make any necessary changes.</p>
                                                                        </div>
                                                                    ),
                                                                    onOk: () => {
                                                                        this.fetch();
                                                                        this.setState({uploading: false});
                                                                    }
                                                                });
                                                            } else if (info.file.status === 'error') {
                                                                this.setState({uploading: false});
                                                            }
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={this.state.uploading}
                                                            loading={this.state.uploading}
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Upload>
                                                    {typeof(this.state.driverData.driver_trainer_compliance) !== 'undefined' && typeof(this.state.driverData.driver_trainer_compliance.working_with_children) !== 'undefined' && this.state.driverData.driver_trainer_compliance.working_with_children.length > 0 && (
                                                        <p className="dt-compliance-history">
                                                            <Button
                                                                type="link"
                                                                onClick={() => {this.download_paperwork(this.state.driverData.driver_trainer_compliance.working_with_children[0].id);}}
                                                                disabled={this.state.downloading}
                                                                loading={this.state.downloading}
                                                            >
                                                                Current
                                                            </Button>
                                                            {this.state.driverData.driver_trainer_compliance.working_with_children.length > 1 && (
                                                                <Button
                                                                    type="link"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            dtComplianceModalVisible: true,
                                                                            dtComplianceModalHeader: 'Working With Children',
                                                                            dtComplianceModalField: 'working_with_children'
                                                                        });
                                                                    }}
                                                                >
                                                                    History
                                                                </Button>
                                                            )}
                                                        </p>
                                                    )}
                                                </Form.Item>
                                            </fieldset>
                                        </Panel>

                                        <Panel header="Insurance Details" key="4">
                                            <fieldset>
                                                <legend>Vehicle</legend>
                                                <Form.Item label="Insurer" >
                                                    {getFieldDecorator('vehicle_insurance_insurer', {
                                                        rules: [{ required: true, message: 'Please enter an Insurer.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_insurance_insurer : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Number" >
                                                    {getFieldDecorator('vehicle_insurance_number', {
                                                        rules: [{ required: true, message: 'Please enter an Insurance Number.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_insurance_number : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Expiry" >
                                                    {getFieldDecorator('vehicle_insurance_expiry', {
                                                        rules: [{ required: true, message: 'Please enter an Insurance Expiry Date.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' && this.state.driverData.vehicle_insurance_expiry !== null ? moment(this.state.driverData.vehicle_insurance_expiry) : null)
                                                    })(
                                                        <DatePicker
                                                            disabled={!this.props.office_user ? true : false}
                                                            required="required"
                                                            allowClear={false}
                                                            placeholder='Select Expiry Date'
                                                            format={dateFormatList}
                                                            onChange={(e) => this.updateDateValue('vehicle_insurance_expiry', e)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Conditions" >
                                                    {getFieldDecorator('vehicle_insurance_conditions', {
                                                        rules: [{ required: true, message: 'Please enter any Insurance Conditions.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.vehicle_insurance_conditions : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Vehicle Insurance Paperwork" >
                                                    <Upload
                                                        name= 'file'
                                                        showUploadList={false}
                                                        action= {config.apiRoot + '/drivers/upload'}
                                                        headers={{
                                                            Authorization: 'Bearer ' + api.getAuthToken()
                                                        }}
                                                        data={{
                                                            chosen_driver: this.props.chosen_driver,
                                                            field: 'vehicle_insurance'
                                                        }}
                                                        onChange={(info) => {
                                                            this.setState({uploading: true});
                                                            if (info.file.status === 'done') {
                                                                Modal.success({
                                                                    title: "Upload Successful!",
                                                                    content: (
                                                                        <div>
                                                                            <p>The franchise team has been notified - they will validate the document and make any necessary changes.</p>
                                                                        </div>
                                                                    ),
                                                                    onOk: () => {
                                                                        this.fetch();
                                                                        this.setState({uploading: false});
                                                                    }
                                                                });
                                                            } else if (info.file.status === 'error') {
                                                                this.setState({uploading: false});
                                                            }
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={this.state.uploading}
                                                            loading={this.state.uploading}
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Upload>
                                                    {typeof(this.state.driverData.driver_trainer_compliance) !== 'undefined' && typeof(this.state.driverData.driver_trainer_compliance.vehicle_insurance) !== 'undefined' && this.state.driverData.driver_trainer_compliance.vehicle_insurance.length > 0 && (
                                                        <p className="dt-compliance-history">
                                                            <Button
                                                                type="link"
                                                                onClick={() => {this.download_paperwork(this.state.driverData.driver_trainer_compliance.vehicle_insurance[0].id);}}
                                                                disabled={this.state.downloading}
                                                                loading={this.state.downloading}
                                                            >
                                                                Current
                                                            </Button>
                                                            {this.state.driverData.driver_trainer_compliance.vehicle_insurance.length > 1 && (
                                                                <Button
                                                                    type="link"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            dtComplianceModalVisible: true,
                                                                            dtComplianceModalHeader: 'Vehicle Insurance',
                                                                            dtComplianceModalField: 'vehicle_insurance'
                                                                        });
                                                                    }}
                                                                >
                                                                    History
                                                                </Button>
                                                            )}
                                                        </p>
                                                    )}
                                                </Form.Item>
                                            </fieldset>
                                            <fieldset>
                                                <legend>Indemnity</legend>
                                                <Form.Item label="Insurer" >
                                                    {getFieldDecorator('indemnity_insurance_insurer', {
                                                        rules: [{ required: true, message: 'Please enter an Insurer.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.indemnity_insurance_insurer : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Number" >
                                                    {getFieldDecorator('indemnity_insurance_number', {
                                                        rules: [{ required: true, message: 'Please enter an Insurance Number.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.indemnity_insurance_number : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Expiry" >
                                                    {getFieldDecorator('indemnity_insurance_expiry', {
                                                        rules: [{ required: true, message: 'Please enter an Insurance Expiry Date.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' && this.state.driverData.indemnity_insurance_expiry !== null ? moment(this.state.driverData.indemnity_insurance_expiry) : null)
                                                    })(
                                                        <DatePicker
                                                            disabled={!this.props.office_user ? true : false}
                                                            required="required"
                                                            allowClear={false}
                                                            placeholder='Select Expiry Date'
                                                            format={dateFormatList}
                                                            onChange={(e) => this.updateDateValue('indemnity_insurance_expiry', e)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Conditions" >
                                                    {getFieldDecorator('indemnity_insurance_conditions', {
                                                        rules: [{ required: true, message: 'Please enter any Insurance Conditions.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.indemnity_insurance_conditions : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Indemnity Insurance Paperwork" >
                                                    <Upload
                                                        name= 'file'
                                                        showUploadList={false}
                                                        action= {config.apiRoot + '/drivers/upload'}
                                                        headers={{
                                                            Authorization: 'Bearer ' + api.getAuthToken()
                                                        }}
                                                        data={{
                                                            chosen_driver: this.props.chosen_driver,
                                                            field: 'indemnity_insurance'
                                                        }}
                                                        onChange={(info) => {
                                                            this.setState({uploading: true});
                                                            if (info.file.status === 'done') {
                                                                Modal.success({
                                                                    title: "Upload Successful!",
                                                                    content: (
                                                                        <div>
                                                                            <p>The franchise team has been notified - they will validate the document and make any necessary changes.</p>
                                                                        </div>
                                                                    ),
                                                                    onOk: () => {
                                                                        this.fetch();
                                                                        this.setState({uploading: false});
                                                                    }
                                                                });
                                                            } else if (info.file.status === 'error') {
                                                                this.setState({uploading: false});
                                                            }
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={this.state.uploading}
                                                            loading={this.state.uploading}
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Upload>
                                                    {typeof(this.state.driverData.driver_trainer_compliance) !== 'undefined' && typeof(this.state.driverData.driver_trainer_compliance.indemnity_insurance) !== 'undefined' && this.state.driverData.driver_trainer_compliance.indemnity_insurance.length > 0 && (
                                                        <p className="dt-compliance-history">
                                                            <Button
                                                                type="link"
                                                                onClick={() => {this.download_paperwork(this.state.driverData.driver_trainer_compliance.indemnity_insurance[0].id);}}
                                                                disabled={this.state.downloading}
                                                                loading={this.state.downloading}
                                                            >
                                                                Current
                                                            </Button>
                                                            {this.state.driverData.driver_trainer_compliance.indemnity_insurance.length > 1 && (
                                                                <Button
                                                                    type="link"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            dtComplianceModalVisible: true,
                                                                            dtComplianceModalHeader: 'Indemnity Insurance',
                                                                            dtComplianceModalField: 'indemnity_insurance'
                                                                        });
                                                                    }}
                                                                >
                                                                    History
                                                                </Button>
                                                            )}
                                                        </p>
                                                    )}
                                                </Form.Item>
                                            </fieldset>
                                            <fieldset>
                                                <legend>Public Liability</legend>
                                                <Form.Item label="Insurer" >
                                                    {getFieldDecorator('liability_insurance_insurer', {
                                                        rules: [{ required: true, message: 'Please enter an Insurer.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.liability_insurance_insurer : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Number" >
                                                    {getFieldDecorator('liability_insurance_number', {
                                                        rules: [{ required: true, message: 'Please enter an Insurance Number.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.liability_insurance_number : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Expiry" >
                                                    {getFieldDecorator('liability_insurance_expiry', {
                                                        rules: [{ required: true, message: 'Please enter an Insurance Expiry Date.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' && this.state.driverData.liability_insurance_expiry !== null ? moment(this.state.driverData.liability_insurance_expiry) : null)
                                                    })(
                                                        <DatePicker
                                                            disabled={!this.props.office_user ? true : false}
                                                            required="required"
                                                            allowClear={false}
                                                            placeholder='Select Expiry Date'
                                                            format={dateFormatList}
                                                            onChange={(e) => this.updateDateValue('liability_insurance_expiry', e)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Conditions" >
                                                    {getFieldDecorator('liability_insurance_conditions', {
                                                        rules: [{ required: true, message: 'Please enter any Insurance Conditions.' }],
                                                        initialValue: (typeof this.state.driverData !== 'undefined' ? this.state.driverData.liability_insurance_conditions : null)
                                                    })(
                                                        <Input
                                                            required="required"
                                                            readOnly={!this.props.office_user ? true : false}
                                                            onBlur={(e) => this.updateValue(e.target)}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Liability Insurance Paperwork" >
                                                    <Upload
                                                        name= 'file'
                                                        showUploadList={false}
                                                        action= {config.apiRoot + '/drivers/upload'}
                                                        headers={{
                                                            Authorization: 'Bearer ' + api.getAuthToken()
                                                        }}
                                                        data={{
                                                            chosen_driver: this.props.chosen_driver,
                                                            field: 'liability_insurance'
                                                        }}
                                                        onChange={(info) => {
                                                            this.setState({uploading: true});
                                                            if (info.file.status === 'done') {
                                                                Modal.success({
                                                                    title: "Upload Successful!",
                                                                    content: (
                                                                        <div>
                                                                            <p>The franchise team has been notified - they will validate the document and make any necessary changes.</p>
                                                                        </div>
                                                                    ),
                                                                    onOk: () => {
                                                                        this.fetch();
                                                                        this.setState({uploading: false});
                                                                    }
                                                                });
                                                            } else if (info.file.status === 'error') {
                                                                this.setState({uploading: false});
                                                            }
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={this.state.uploading}
                                                            loading={this.state.uploading}
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Upload>
                                                    {typeof(this.state.driverData.driver_trainer_compliance) !== 'undefined' && typeof(this.state.driverData.driver_trainer_compliance.liability_insurance) !== 'undefined' && this.state.driverData.driver_trainer_compliance.liability_insurance.length > 0 && (
                                                        <p className="dt-compliance-history">
                                                            <Button
                                                                type="link"
                                                                onClick={() => {this.download_paperwork(this.state.driverData.driver_trainer_compliance.liability_insurance[0].id);}}
                                                                disabled={this.state.downloading}
                                                                loading={this.state.downloading}
                                                            >
                                                                Current
                                                            </Button>
                                                            {this.state.driverData.driver_trainer_compliance.liability_insurance.length > 1 && (
                                                                <Button
                                                                    type="link"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            dtComplianceModalVisible: true,
                                                                            dtComplianceModalHeader: 'Liability Insurance',
                                                                            dtComplianceModalField: 'liability_insurance'
                                                                        });
                                                                    }}
                                                                >
                                                                    History
                                                                </Button>
                                                            )}
                                                        </p>
                                                    )}
                                                </Form.Item>
                                            </fieldset>
                                        </Panel>
                                    </Collapse>
                                </Form>
                                <Modal
                                    visible={this.state.dtComplianceModalVisible}
                                    title={'Paperwork History - ' + this.state.dtComplianceModalHeader}
                                    className= 'create-choice-modal'
                                    onCancel={()=>{
                                        this.setState({
                                            dtComplianceModalVisible: false
                                        }, () => {
                                            this.setState({
                                                dtComplianceModalHeader: null,
                                                dtComplianceModalField: null
                                            });
                                        });
                                    }}
                                    footer={[
                                        <Button
                                            key="okay"
                                            type="primary"
                                            onClick={()=>{
                                                this.setState({
                                                    dtComplianceModalVisible: false
                                                }, () => {
                                                    this.setState({
                                                        dtComplianceModalHeader: null,
                                                        dtComplianceModalField: null
                                                    });
                                                });
                                            }}
                                        >
                                            OK
                                        </Button>
                                    ]}
                                >
                                    <Table
                                        className="dt-compliance-table"
                                        size="middle"
                                        columns={[
                                            {
                                                title: 'Upload Date',
                                                dataIndex: 'created',
                                                render: (text) =>  {
                                                    return moment(text).format('DD/MM/YYYY HH:mm:ss');
                                                }
                                            },
                                            {
                                                title: 'Paperwork',
                                                dataIndex: 'url',
                                                render: (text, record) => {
                                                    return <Button
                                                                type="link"
                                                                onClick={() => {this.download_paperwork(record.id);}}
                                                                disabled={this.state.downloading}
                                                                loading={this.state.downloading}
                                                            >
                                                                Download
                                                            </Button>;
                                                }
                                            }
                                        ]}
                                        rowKey={record => record.id}
                                        dataSource={typeof(this.state.driverData.driver_trainer_compliance) !== 'undefined' && typeof(this.state.driverData.driver_trainer_compliance[this.state.dtComplianceModalField]) !== 'undefined' ? this.state.driverData.driver_trainer_compliance[this.state.dtComplianceModalField] : []}
                                        pagination={false}
                                    />
                                </Modal>
                            </>
                        )
                    }
                </Col>
            </Row>
        );
    }
};

const DTProfileForm = Form.create({name: 'dt_profile_form'})(DTProfile);

export default withRouter(DTProfileForm);
